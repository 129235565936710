<template>
  <v-app>
    <v-container
      class="pa-0 mx-1 my-1"
      fluid
      v-if="this.datosComunas.status != 200"
    >
      <v-dialog
        persistent
        style="z-index: 9999999"
        v-model="dialogAllMetadata"
        max-width="600px"
      >
        <v-card>
          <v-card-title> METADATA </v-card-title>
          <v-card-text>
            <h2
              v-for="(att, att2) in ffOrderDetails"
              :key="att2"
              style="font-size: 12px"
            >
              <div v-if="att2 !== 'items' && att2 !== 'item_count'">
                {{ att2 }}: {{ att }}
              </div>
            </h2>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              class="white--text"
              color="rayo"
              @click="dialogAllMetadata = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogOrdenDesconocida" max-width="400px">
        <v-card>
          <v-card-title> ADVERTENCIA </v-card-title>
          <v-card-text>
            La orden ingresada no se encuentra en el sistema.
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              class="white--text"
              color="rayo"
              @click="dialogOrdenDesconocida = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialogClientes" max-width="500px">
        <v-card>
          <v-card-title> Selecciona Cliente y Warehouse </v-card-title>
          <v-card-text>
            <v-select
              v-model="selectedWarehouse"
              :items="warehouses"
              label="Selecciona un warehouse"
              item-text="name"
              return-object
            ></v-select>

            <v-select
              v-model="selectedCustomer"
              :items="clientes"
              label="Selecciona un cliente"
              item-text="name_customer"
              return-object
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :disabled="toggleDisableButtonStart()"
              color="rayo"
              text
              @click="
                dialogClientes = false;
                inicioOperacion();
              "
            >
              OK
            </v-btn>
            <v-btn
              color="rayo"
              class="white--text"
              @click="dialogClientes = false"
            >
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialogFFOrderId">
        <v-card v-if="showScannerFFOrderId === true">
          <v-card-title>FF ORDER_ID</v-card-title>

          <v-card-text>
            <CameraScanner @decode="onDecodeFFOrderId" />
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="rayo"
              class="white--text"
              @click="showScannerFFOrderId = false"
            >
              Cancelar Scann Order Id FF
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card>
          <v-card-title>FF ORDER ID MAIN SCAN</v-card-title>
          <v-card-text>
            <v-flex style="text-align: center">
              <v-text-field
                solo-inverted
                v-on:keyup.enter="getItemFFOrderDetails()"
                v-model="ff_order_id"
                label="Ingresa N° de pedido"
                filled
              ></v-text-field>

              <v-overlay :value="loader">
                <v-progress-circular
                  color="rayo"
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </v-flex>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              class="ma-1"
              :color="showScannerFFOrderId === false ? 'black' : 'rayo'"
              dark
              @click="showScannerFFOrderId = !showScannerFFOrderId"
            >
              <v-icon dark> mdi-barcode </v-icon>
            </v-btn>

            <v-btn
              color="rayo"
              class="white--text"
              @click="dialogFFOrderId = false"
            >
              Cancelar
            </v-btn>
            <v-btn @click="getItemFFOrderDetails()">Buscar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" persistent max-width="560">
        <v-card>
          <v-card-title class="headline">CONFIRMACIÓN</v-card-title>
          <v-card-text
            >Si estás completamente segur@ de querer finalizar la recepción
            presiona CONFIRMAR. De lo contrario te invitamos a revisar la
            información cargada.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="finOperacion()">
              Confirmar
            </v-btn>
            <v-btn color="error" text @click="dialog = false"> Cerrar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog style="z-index: 99999" v-model="dialogItemsPedidos">
        <v-card class="mx-auto">
          <v-subheader>LISTA DE ITEMS A PICKEAR</v-subheader>

          <v-data-table
            :headers="headers"
            :items="ffOrderDetails.items"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td class="text-xs-center">
                {{ props.item_id }}
                <v-icon
                  color="pink"
                  center
                  @click="openLink(props.item_id)"
                  small
                  >mdi-content-copy</v-icon
                >
              </td>
              <td class="text-xs-center">
                {{ props.item_warehouse }}
              </td>
              <td class="text-xs-center">
                {{ props.item_name }}
              </td>

              <td class="text-xs-center">
                {{ props.item_category }}
              </td>

              <td class="text-xs-center">
                <v-img src="props.img_url"></v-img>
              </td>
            </template>

            <template v-slot:item.img_url="{ item }">
              <v-img
                max-height="150"
                max-width="150"
                :src="item.img_url"
                style="width: 50px; height: 50px"
              ></v-img>
            </template>
          </v-data-table>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="dialogItemsPedidos = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>

        <!-- SNACKBAR copy ASIGNACION -->
        <v-snackbar top v-model="snackbarCopy" :timeout="timeoutCopy">
          ¡Copiado!
          <template>
            <v-btn text color="pink" @click="snackbarCopy = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
        <!-- FIN SNACKBAR copy ASIGNACION -->
      </v-dialog>
      <div
        class="pa-0 ma-0"
        v-if="
          dialogFFOrderId === false &&
          ff_order_id !== null &&
          ff_order_id !== ''
        "
      >
        <!-- <v-layout  class=" py-2 stickyStyle" row no-gutters align-center justify-center> -->
        <v-layout
          style="background-color: white"
          class="py-2 stickyStyle"
          row
          no-gutters
          align-center
          justify-center
        >
          <h5 class="mx-2">
            Progreso Picking {{ ff_order_id }}: {{ pickedOrder.items.length }}/
            {{ ffOrderDetails.items.length }}
          </h5>
        </v-layout>
        <v-layout
          style="background-color: white"
          class="py-2 pa-0 stickyStyle"
          row
          no-gutters
          align-center
          justify-center
        >
          <!-- <v-chip  @click="dialogFFOrderId=true" small color="rayo" label text-color="white">Nueva FF Order</v-chip>        -->

          <h2
            v-for="(att, att2) in ffOrderDetails"
            :key="att2"
            style="font-size: 12px"
          >
            <v-btn
              x-small
              @click="dialogItemsPedidos = true"
              class="blue--text"
              v-if="att2 === 'item_count'"
            >
              Detalle ({{ att }})
            </v-btn>
            <v-btn
              x-small
              @click="dialogAllMetadata = true"
              class="blue--text"
              v-if="att2 === 'item_count'"
            >
              Metadata
            </v-btn>
          </h2>

          <v-btn
            x-small
            color="rayo"
            @click="dialogFFOrderId = true"
            class="white--text"
          >
            Nueva Orden FF
          </v-btn>
          <v-progress-linear
            class="mt-2"
            stripped
            disabled
            v-model="currentProgress"
            height="35"
          >
            <strong>{{ Math.ceil(currentProgress) }}%</strong>
          </v-progress-linear>
        </v-layout>
        <v-layout
          style="background-color: white"
          class="py-2 stickyStyleCamera"
          row
          no-gutters
          align-center
          justify-center
        >
          <!-- <v-layout class=" py-2 stickyStyleCamera" row no-gutters align-center justify-center> -->

          <CameraScanner @decode="onDecode" />
        </v-layout>
        <v-layout
          pa-0
          ma-0
          style="background-color: white"
          class="py-2 stickyStyleBarcode"
          row
          no-gutters
          align-center
          justify-center
        >
          <!-- <v-layout class=" py-2 stickyStyleBarcode" row no-gutters align-center justify-center> -->

          <v-text-field
            @click:append-outer="onDecode()"
            append-outer-icon="mdi-barcode"
            v-model="scannedCodeMain"
            color="rayo"
            label
            text-color="white"
            ref="inputBarcode"
            autofocus
          ></v-text-field>
        </v-layout>

        <v-layout
          style="background-color: white"
          class="py-2 stickyStyleBarcode"
          row
          no-gutters
          align-center
          justify-center
        >
          <!-- <v-layout class=" py-2 stickyStyleBarcode" row no-gutters align-center justify-center> -->

          <v-chip x-large color="rayo" label text-color="white">{{
            scannedCodeMain
          }}</v-chip>
        </v-layout>

        <v-layout pa-0 ma-0 row no-gutters align-center justify-center>
          <!-- <v-flex xs12 style="text-align: center">
            <v-flex mt-3>
              <div v-if="ffOrderDetails.customer_id !== undefined">
                <v-data-iterator
                  :items="Object.keys(summaryCategory)"
                  class="mt-3"
                  hide-default-footer
                >
                  <template v-slot:header>
                    <v-toolbar class="mb-2" co lor="pink darken-5" dark flat>
                      <v-toolbar-title>Resumen por Categoría</v-toolbar-title>
                    </v-toolbar>
                  </template>

                  <template v-slot:default="props">
                    <v-row>
                      <v-col v-for="item in props.items" :key="item" cols="12">
                        <v-card>
                          <v-card-title class="subheading font-weight-bold">
                      
                            {{ item }}: {{ summaryCategory[item].length }}
                          </v-card-title>

                          <v-divider></v-divider>

                          <v-list dense>
                            <v-list-item
                              v-for="(i, name, ind) in summaryCategory[item]"
                              :key="name"
                            >
                              <v-list-item-content class="align-end">
                                {{ parseInt(name) + 1 }}- {{ i.item_name }}

                                <v-icon color="teal" v-if="i.picked == true"
                                  >mdi-check-circle</v-icon
                                >
                                <v-icon v-else color="grey" else
                                  >mdi-checkbox-blank-circle-outline</v-icon
                                >
                       
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:footer>
                    <v-toolbar class="mt-2" color="rayo" dark flat>
                      <v-toolbar-title class="subheading">
                        This is a footer
                      </v-toolbar-title>
                    </v-toolbar>
                  </template>
                </v-data-iterator>

                <v-data-iterator
                  :items="Object.keys(summaryItemId)"
                  class="mt-3"
                  hide-default-footer
                >
                  <template v-slot:header>
                    <v-toolbar class="mb-2" color="rayo darken-5" dark flat>
                      <v-toolbar-title>Resumen por Items</v-toolbar-title>
                    </v-toolbar>
                  </template>

                  <template v-slot:default="props">
                    <v-row>
                      <v-col v-for="item in props.items" :key="item" cols="12">
                        <v-card>
                          <v-card-title class="subheading font-weight-bold">
                       
                            {{ item }}: {{ summaryItemId[item].length }}
                            <v-icon
                              color="pink"
                              class="ml-2"
                              @click="scanItemPickingClick(item)"
                              >mdi-circle</v-icon
                            >
                            <v-icon
                              color="pink"
                              class="ml-2"
                              @click="zXing = true"
                              >mdi-barcode-scan</v-icon
                            >
                          </v-card-title>

                          <v-divider></v-divider>

                          <v-list dense>
                            <v-list-item
                              v-for="(i, name, ind) in summaryItemId[item]"
                              :key="name"
                            >
                              <v-list-item-content class="align-end">
                                {{ parseInt(name) + 1 }}- {{ i.item_name }}

                                <v-icon color="teal" v-if="i.picked == true"
                                  >mdi-check-circle</v-icon
                                >
                                <v-icon v-else color="grey" else
                                  >mdi-checkbox-blank-circle-outline</v-icon
                                >
                           
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:footer>
                    <v-toolbar class="mt-2" color="rayo" dark flat>
                      <v-toolbar-title class="subheading">
                        This is a footer
                      </v-toolbar-title>
                    </v-toolbar>
                  </template>
                </v-data-iterator>

                <v-data-iterator
                  :items="Object.keys(summarySlot)"
                  class="mt-3"
                  hide-default-footer
                >
                  <template v-slot:header>
                    <v-toolbar class="mb-2" color="teal darken-5" dark flat>
                      <v-toolbar-title>Resumen por Slotss</v-toolbar-title>
                    </v-toolbar>
                  </template>

                  <template v-slot:default="props">
                    <v-row>
                      <v-col v-for="item in props.items" :key="item" cols="12">
                        <v-card>
                          <v-card-title class="subheading font-weight-bold">
                         
                            {{ item }}: {{ summarySlot[item].length }}
                          </v-card-title>

                          <v-divider></v-divider>

                          <v-list dense>
                            <v-list-item
                              v-for="(i, name, ind) in summarySlot[item]"
                              :key="name"
                            >
                              <v-list-item-content class="align-end">
                                {{ parseInt(name) + 1 }}- {{ i.item_name }}

                                <v-icon color="teal" v-if="i.picked == true"
                                  >mdi-check-circle</v-icon
                                >
                                <v-icon v-else color="grey" else
                                  >mdi-checkbox-blank-circle-outline</v-icon
                                >

                              
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:footer>
                    <v-toolbar class="mt-2" color="rayo" dark flat>
                      <v-toolbar-title class="subheading">
                        This is a footer
                      </v-toolbar-title>
                    </v-toolbar>
                  </template>
                </v-data-iterator>
              </div>
              <v-btn class="ma-2" v-if="botonFin == true" color="rayo" dark>
                INICIAR PICKING.
              </v-btn>
            </v-flex>

            <br />

            <v-btn
              class="ma-2"
              v-if="botonFin == true"
              color="#DC353F"
              @click="dialog = true"
              dark
            >
              Fin Recepción
            </v-btn>

            <v-overlay :value="loader">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>

      
          </v-flex> -->

          <v-card>
            <v-tabs
              v-model="tab"
              background-color="rayo"
              centered
              dark
              icons-and-text
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#tab-category">
                Category
                <v-icon>mdi-view-dashboard</v-icon>
              </v-tab>

              <v-tab href="#tab-slot">
                Slot
                <v-icon>mdi-library-shelves</v-icon>
              </v-tab>

              <v-tab href="#tab-item">
                Item
                <v-icon>mdi-cube</v-icon>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item :value="'tab-category'">
                <v-card flat>
                  <v-card-text>
                    <v-data-iterator
                      :items="Object.keys(summaryCategory)"
                      class="mt-3"
                      hide-default-footer
                    >
                      <template v-slot:header>
                        <v-toolbar class="mb-2" color="pink darken-5" dark flat>
                          <v-toolbar-title
                            >Resumen por Categoría</v-toolbar-title
                          >
                        </v-toolbar>
                      </template>

                      <template v-slot:default="props">
                        <v-row>
                          <v-col
                            v-for="item in props.items"
                            :key="item"
                            cols="12"
                          >
                            <v-card>
                              <v-card-title class="subheading font-weight-bold">
                                <!-- posicionKeyº:{{summaryCategory.indexOf(item)}} -->
                                <!-- nameKey:{{item}} -->
                                {{ item }}: {{ summaryCategory[item].length }}
                              </v-card-title>

                              <v-divider></v-divider>

                              <v-list dense>
                                <v-list-item
                                  v-for="(i, name, ind) in summaryCategory[
                                    item
                                  ]"
                                  :key="name"
                                >
                                  <v-list-item-content class="align-end">
                                    {{ parseInt(name) + 1 }}- {{ i.item_name }}

                                    <v-icon color="teal" v-if="i.picked == true"
                                      >mdi-check-circle</v-icon
                                    >
                                    <v-icon v-else color="grey" else
                                      >mdi-checkbox-blank-circle-outline</v-icon
                                    >
                                    <!-- {{summaryItemId[i.item_id].length}} -->
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>

                      <template v-slot:footer>
                        <v-toolbar class="mt-2" color="rayo" dark flat>
                          <v-toolbar-title class="subheading">
                            <h5 class="mx-2">
                              Progreso Picking {{ ff_order_id }}:
                              {{ pickedOrder.items.length }}/
                              {{ ffOrderDetails.items.length }}
                            </h5>
                          </v-toolbar-title>
                        </v-toolbar>
                      </template>
                    </v-data-iterator>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item :value="'tab-slot'">
                <v-card flat>
                  <v-card-text>
                    <v-data-iterator
                      :items="Object.keys(summarySlot)"
                      class="mt-3"
                      hide-default-footer
                    >
                      <template v-slot:header>
                        <v-toolbar class="mb-2" color="teal darken-5" dark flat>
                          <v-toolbar-title>Resumen por Slotss</v-toolbar-title>
                        </v-toolbar>
                      </template>

                      <template v-slot:default="props">
                        <v-row>
                          <v-col
                            v-for="item in props.items"
                            :key="item"
                            cols="12"
                          >
                            <v-card>
                              <v-card-title class="subheading font-weight-bold">
                                <!-- posicionKeyº:{{summaryCategory.indexOf(item)}} -->
                                <!-- nameKey:{{item}} -->
                                {{ item }}: {{ summarySlot[item].length }}
                              </v-card-title>

                              <v-divider></v-divider>

                              <v-list dense>
                                <v-list-item
                                  v-for="(i, name, ind) in summarySlot[item]"
                                  :key="name"
                                >
                                  <v-list-item-content class="align-end">
                                    {{ parseInt(name) + 1 }}- {{ i.item_name }}

                                    <v-icon color="teal" v-if="i.picked == true"
                                      >mdi-check-circle</v-icon
                                    >
                                    <v-icon v-else color="grey" else
                                      >mdi-checkbox-blank-circle-outline</v-icon
                                    >

                                    <!-- {{summaryItemId[i.item_id].length}} -->
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>

                      <template v-slot:footer>
                        <v-toolbar class="mt-2" color="rayo" dark flat>
                          <v-toolbar-title class="subheading">
                            <h5 class="mx-2">
                              Progreso Picking {{ ff_order_id }}:
                              {{ pickedOrder.items.length }}/
                              {{ ffOrderDetails.items.length }}
                            </h5>
                          </v-toolbar-title>
                        </v-toolbar>
                      </template>
                    </v-data-iterator>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item :value="'tab-item'">
                <v-card flat>
                  <v-card-text>
                    <v-data-iterator
                      :items="Object.keys(summaryItemId)"
                      class="mt-3"
                      hide-default-footer
                    >
                      <template v-slot:header>
                        <v-toolbar class="mb-2" color="rayo darken-5" dark flat>
                          <v-toolbar-title>Resumen por Items</v-toolbar-title>
                        </v-toolbar>
                      </template>

                      <template v-slot:default="props">
                        <v-row>
                          <v-col
                            v-for="item in props.items"
                            :key="item"
                            cols="12"
                          >
                            <v-card>
                              <v-card-title class="subheading font-weight-bold">
                                <!-- posicionKeyº:{{summaryCategory.indexOf(item)}} -->
                                <!-- nameKey:{{item}} -->
                                {{ item }}: {{ summaryItemId[item].length }}
                                <v-icon
                                  color="pink"
                                  class="ml-2"
                                  @click="scanItemPickingClick(item)"
                                  >mdi-circle</v-icon
                                >
                                <v-icon
                                  color="pink"
                                  class="ml-2"
                                  @click="zXing = true"
                                  >mdi-barcode-scan</v-icon
                                >
                              </v-card-title>

                              <v-divider></v-divider>

                              <v-list dense>
                                <v-list-item
                                  v-for="(i, name, ind) in summaryItemId[item]"
                                  :key="name"
                                >
                                  <v-list-item-content class="align-end">
                                    {{ parseInt(name) + 1 }}- {{ i.item_name }}

                                    <v-icon color="teal" v-if="i.picked == true"
                                      >mdi-check-circle</v-icon
                                    >
                                    <v-icon v-else color="grey" else
                                      >mdi-checkbox-blank-circle-outline</v-icon
                                    >
                                    <!-- {{summaryItemId[i.item_id].length}} -->
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>

                      <template v-slot:footer>
                        <v-toolbar class="mt-2" color="rayo" dark flat>
                          <v-toolbar-title class="subheading">
                            <h5 class="mx-2">
                              Progreso Picking {{ ff_order_id }}:
                              {{ pickedOrder.items.length }}/
                              {{ ffOrderDetails.items.length }}
                            </h5>
                          </v-toolbar-title>
                        </v-toolbar>
                      </template>
                    </v-data-iterator>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-layout>
      </div>

      <v-container
        v-if="ff_order_id === null || ff_order_id === ''"
        fill-height
      >
        <v-row justify="center" align="center">
          <v-chip
            @click="dialogFFOrderId = true"
            x-large
            color="rayo"
            label
            text-color="white"
            >INICIA NUEVO PICKING
          </v-chip>
        </v-row>
      </v-container>
    </v-container>
  </v-app>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
var ObjectID = require("bson-objectid");

import CameraScanner from "./subComponents/CamaraScanner.vue";
Vue.use(VueAxios, axios);
export default {
  components: {
    CameraScanner,
  },
  data() {
    return {
      tab: null,
      dialogAllMetadata: false,
      dialogOrdenDesconocida: false,
      zXing: false,
      scannedCodeMain: "",
      showScannerFFOrderId: false,
      dialogFFOrderId: true,
      sumo: [],
      summaryCategory: [],
      summaryItemId: [],
      summarySlot: [],
      allSummary: [],
      progress: 0,
      pickedOrder: {
        items: [],
      },

      ff_order_id: null,
      ffOrderDetails: { items: [] },
      loader: false,
      currentIngress: {
        customer_name: "No Iniciado",
        numRecepción: "No iniciado",
        creation_date: "No Iniciado",
        status_name: "No Iniciado",
      },
      selectedCustomer: { _id: null, customer_name: null },
      selectedWarehouse: { _id: null, warehouse_name: null },
      dialogClientes: false,
      ingressStatusList: [],
      baseAPI:
        "https://us-central1-rayoio.cloudfunctions.net/proxy-test-dev-cluster1",
      cluster: "Cluster1",
      fecha: moment().format("YYYY-MM-DD - HH:mm"),
      currentStatus: { status_name: null, status_code: null },
      arrayScan: [],
      datosComunas: [],
      arrayComunas: [],
      objetoProceso: [],
      recuperoData: [],
      itemDetails: {},
      cantItems: 0,
      timeoutCopy: 2000,
      numeroRecepción: null,
      userScan: "",
      itemId: "",
      order_id: "",
      order_idScan: "",
      mensajeZona: "",
      mensajeRuta: "",
      errorDirección: "",
      mensajeCierre: "",
      latPedido: "",
      longPedido: "",
      dibujando: false,
      snackMongo: false,
      botonFin: false,
      inicioProceso: false,
      dialog: false,

      dialogItemsPedidos: false,
      verPedidos: false,
      snackbarCopy: false,
      clientes: [],
      warehouses: [],
      headers: [
        {
          value: "customer_id",
          text: "Ciente",
        },
        {
          value: "item_category",
          text: "Categoria",
        },
        {
          value: "item_id",
          text: "Item Id",
        },
        {
          value: "item_name",
          text: "Description",
        },
        {
          value: "img_url",
          text: "Imagen",
        },
      ],
      footerProps: { "items-per-page-options": [30, 50, 100] },
      y: "top",
      snackText: "INGRESADO CON ÉXITO",
      timeout: 5000,
      backgroundColor: "background-color: #ffffff",
      myStyle: {
        height: "100vh",
        color: "#fff",
        "text-align": "center",
      },
      fuente: {
        "font-size": "150px",
      },
      errorScan: {
        backgroundColor: "#DC353F",
      },
    };
  },
  computed: {
    currentProgress() {
      if (
        this.pickedOrder.items.length !== undefined &&
        this.ffOrderDetails.item_count > 0
      ) {
        return (
          (this.pickedOrder.items.length / this.ffOrderDetails.item_count) * 100
        );
      } else {
        return 0;
      }
    },
  },
  watch: {
    "pickedOrder.items": function (val) {
      console.log(val);
    },
  },

  methods: {
    onDecodeFFOrderId(inIn) {
      alert(inIn);
      this.ff_order_id = inIn;
    },

    onDecode(inIn) {
      if (this.scannedCodeMain !== "") {
        this.scannedCodeMain = "";

        // alert(inIn)
        this.scannedCodeMain = inIn;
        this.scanItemPicking(inIn);
      }
    },
    fullTransforma() {
      let temp1 = this.groupByAtt(this.ffOrderDetails.items, "item_category");
      let temp2 = this.groupByAtt(this.ffOrderDetails.items, "item_id");
      let temp3 = this.groupByAtt(this.ffOrderDetails.items, "item_slot");

      this.transformaSummary("category", temp1);
      this.transformaSummary("itemId", temp2);
      this.transformaSummary("slot", temp3);
    },
    async scanItemPicking(itemIdIn) {
      let y = this.ffOrderDetails.items.filter(
        (x) => x.item_id === itemIdIn && x.picked !== true
      )[0];
      let indexItem = this.ffOrderDetails.items.indexOf(y);
      console.log("posicion es:", indexItem);
      console.log("item", y);
      this.fullTransforma();
      if (indexItem > -1) {
        this.ffOrderDetails.items[indexItem].picked = true;
        this.pickedOrder.items.push(this.ffOrderDetails.items[indexItem]);
      } else {
        alert("No puedes pickear más items de los solicitados en. la orden.");
      }
      this.scannedCodeMain = "";
      this.$refs.inputBarcode.focus();
    },
    scanItemPickingClick(itemIdIn) {
      console.log(itemIdIn);
      let y = this.ffOrderDetails.items.filter(
        (x) => x.item_id === itemIdIn && x.picked !== true
      )[0];
      let indexItem = this.ffOrderDetails.items.indexOf(y);
      console.log("posicion es:", indexItem);
      console.log("item", y);
      this.fullTransforma();
      if (indexItem > -1) {
        this.ffOrderDetails.items[indexItem].picked = true;
        this.pickedOrder.items.push(this.ffOrderDetails.items[indexItem]);
      } else {
        alert("No puedes pickear más items de los solicitados en. la orden.");
      }

      this.scannedCodeMain = "";
      this.$refs.inputBarcode.focus();
    },
    transformaSummary(attrIn, tempIn) {
      switch (attrIn) {
        case "category":
          this.summaryCategory = {};

          for (let a in tempIn) {
            this.summaryCategory[a] = tempIn[a];
          }
          break;
        case "itemId":
          this.summaryItemId = {};

          for (let a in tempIn) {
            this.summaryItemId[a] = tempIn[a];
          }
          break;

        case "slot":
          this.summarySlot = {};

          for (let a in tempIn) {
            this.summarySlot[a] = tempIn[a];
          }
          break;
      }
    },
    groupByAtt(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    getClientDetails(idIn) {
      let resuClienteData = this.clientes.filter((x) => x._id === idIn)[0]
        .name_customer;
      if (resuClienteData === undefined) {
        alert("Cliente desconocido");
        return "Cliente desconocido";
      } else {
        return this.clientes.filter((x) => x._id === idIn)[0].name_customer;
      }
    },

    toggleDisableButtonStart() {
      if (
        this.selectedCustomer._id === null ||
        this.selectedWarehouse._id === null
      ) {
        return true;
      } else {
        return false;
      }
    },

    openLink(link) {
      navigator.clipboard.writeText(link);
      this.snackbarCopy = true;
    },

    async listarClientes() {
      const that = this;
      try {
        const respuestaClientes = await axios.get(
          "https://backendservices.rayoapp.com/Customers/customers",
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );
        console.log("Clientes listados!");

        that.clientes = respuestaClientes.data;
        that.snackMongo = true;
        that.snackText = "Lista clientes OK";
      } catch (error) {
        that.snackText = "error";
        that.snackMongo = true;
        console.log(error);
      }
    },

    seleccionClienteWarehouse() {
      this.dialogClientes = true;
    },

    inicioOperacion() {
      const that = this;
      this.botonFin = true;
      this.inicioProceso = true;
      this.verPedidos = true;
      const currentDate = new Date();
      that.currentIngress.numRecepcion = currentDate.getTime();
      that.currentIngress.numRecepcion = currentDate.getTime();
      that.currentStatus = this.ingressStatusList.filter(
        (x) => x.status_code === 1
      )[0];
      that.currentIngress.warehouse_name = that.selectedWarehouse.name;
      that.currentIngress.warehouse_id = that.selectedWarehouse._id;
      that.currentIngress.customer_name = that.selectedCustomer.name_customer;
      that.currentIngress.customer_id = that.selectedCustomer._id;
      that.currentIngress.status_code = that.currentStatus.status_code;
      that.currentIngress.status_name = that.currentStatus.status_name;
      that.currentIngress.status_id = that.currentStatus._id;
      that.cantItems = 0;

      var data = JSON.stringify({
        dataSource: this.cluster,
        database: "RAYO-IO",
        collection: "stock_ingress",
        document: {
          customer_id: that.currentIngress.customer_id,
          customer_name: that.currentIngress.customer_name,
          warehouse_id: that.currentIngress.warehouse_id,
          warehouse_name: that.currentIngress.warehouse_name,
          numRecepcion: that.currentIngress.numRecepcion,
          creation_date: moment().format("YYYY-MM-DD - HH:mm"),
          usr: this.$store.state.user.data.email,
          status_id: that.currentIngress._id,
          status_name: that.currentIngress.status_name,
          status_code: that.currentIngress.status_code,
          items: [],
        },
      });

      var config = {
        method: "post",
        url: this.baseAPI + "/insertOne",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      console.log("BODY INICIO OP", config.data);
      axios(config)
        .then(function (response) {
          console.log(response);

          that.snackText =
            "Pedido Ingresado con id " + response.data.insertedId;
          that.snackMongo = true;
        })

        .catch(function (error) {
          console.log("ERROR", error);

          that.backgroundColor = "background-color: #DC353F";
          alert("Error en llamada a API de Inicio Operación");
          that.inicioProceso = false;
          that.verPedidos = false;
          that.snackText = error;
          that.snackMongo = true;
        });
    },

    // GUARDAMOS REGISTRO DE CADA ESCANEO CON SU N° DE RECEPCIÓN.

    async scanItems() {
      // let cantTotal = this.cantItems
      const that = this;
      let gg = await that.getItemDetails();
      console.log(gg);
      var data = JSON.stringify({
        dataSource: that.cluster,
        database: "RAYO-IO",
        collection: "stock_ingress",
        filter: {
          numRecepcion: that.currentIngress.numRecepcion,
        },
        update: {
          // $push: { "items": { item_id: this.itemId } }
          $push: { items: await that.getItemDetails() },
        },
      });

      var config = {
        method: "put",
        url: this.baseAPI + "/updateOne",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          that.dialog = false;
          that.snackText = "Datos cargados";
          that.snackMongo = true;
          that.cantItems++;
        })
        .catch(function (error) {
          that.dialog = false;
          console.log(error);
        });
    },

    // CERRAMOS RECEPCIÓN.

    finOperacion() {
      this.dialog = false;
      this.loader = true;

      let currentStatus = this.ingressStatusList.filter(
        (x) => x.status_code === 9
      )[0];
      console.log(status);
      // console.log(ObjectID)
      const idSent = new ObjectID(status._id);
      console.log(idSent);
      const that = this;

      var data = JSON.stringify({
        dataSource: that.cluster,
        database: "RAYO-IO",
        collection: "stock_ingress",
        filter: {
          numRecepcion: that.currentIngress.numRecepcion,
        },
        update: {
          $set: {
            end_date: that.currentIngress.creation_date,
            status_id: idSent,
            status_name: currentStatus.status_name,
            status_code: currentStatus.status_code,
          },
        },
      });

      var config = {
        method: "put",
        url: this.baseAPI + "/updateOne",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);

          that.snackText =
            "Recepción de stock finalizada. ¡¡PENDIENTE GENERAR INFORME!!";
          that.snackMongo = true;
          that.botonFin = false;
          that.inicioProceso = false;
          that.loader = false;
        })
        .catch(function (error) {
          console.log(error);
          that.botonFin = false;
          that.inicioProceso = false;
          that.loader = false;
        });
    },
    async getWarehouses() {
      var that = this;

      var data = JSON.stringify({
        dataSource: this.cluster,
        database: "RAYO-IO",
        collection: "warehouse",
      });

      var config = {
        method: "post",
        url: this.baseAPI + "/find",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      // console.log('BODY GET STATUSES', config.data)
      const resultado = await axios(config)
        .then(function (response) {
          that.snackMongo = true;
          that.snackText = "Lista de warehouses OK";

          that.warehouses = response.data.documents;
        })

        .catch(function (error) {
          console.log("ERROR", error);
          that.snackText = error;
          that.snackMongo = true;
        });

      return await resultado;
    },

    async getItemFFOrderDetails() {
      const that = this;
      that.loader = true;

      var data = JSON.stringify({
        dataSource: this.cluster,
        database: "RAYO-IO",
        collection: "ff_orders",
        filter: { ff_order_id: that.ff_order_id },
      });

      var config = {
        method: "post",
        url: this.baseAPI + "/find",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      // console.log('BODY GET STATUSES', config.data)
      const resultado = await axios(config)
        .then(function (response) {
          that.loader = false;
          // console.log(response)
          if (response.data.documents.length === 0) {
            that.ff_order_id = null;
            that.dialogOrdenDesconocida = true;
          } else {
            that.ffOrderDetails = response.data.documents[0];
            // console.log(that.ffOrderDetails)
            that.fullTransforma();
            that.dialogFFOrderId = false;
          }
        })

        .catch(function (error) {
          that.loader = false;
          console.log("ERROR", error);
          that.snackText = error;
          that.snackMongo = true;
        });

      return await resultado;
    },
    async getWarehouseData() {
      var that = this;
      that.loader = true;

      var data = JSON.stringify({
        dataSource: this.cluster,
        database: "RAYO-IO",
        collection: "ff_orders",
        filter: { ff_order_id: that.ff_order_id },
      });

      var config = {
        method: "post",
        url: this.baseAPI + "/find",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      // console.log('BODY GET STATUSES', config.data)
      const resultado = await axios(config)
        .then(function (response) {
          that.loader = false;
          // console.log(response)
          if (response.data.documents.length === 0) {
            alert("FF ORDER ID desconocido");
          } else {
            that.ffOrderDetails = response.data.documents[0];
            console.log(that.ffOrderDetails);
          }
        })

        .catch(function (error) {
          that.loader = false;
          console.log("ERROR", error);
          that.snackText = error;
          that.snackMongo = true;
        });

      return await resultado;
    },
    async getItemDetails() {
      var that = this;

      var data = JSON.stringify({
        dataSource: this.cluster,
        database: "RAYO-IO",
        collection: "items",
        filter: { item_id: that.itemId },
        sort: {},
        limit: 1000,
      });

      var config = {
        method: "post",
        url: this.baseAPI + "/find",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      // console.log('BODY GET STATUSES', config.data)
      const resultado = await axios(config)
        .then(function (response) {
          // console.log(response)
          if (response.data.documents.length === 0) {
            alert("Item desconocido");
            return { item_id: that.itemId };
          } else {
            return response.data.documents[0];
          }
        })

        .catch(function (error) {
          console.log("ERROR", error);
          that.snackText = error;
          that.snackMongo = true;
        });

      return await resultado;
    },

    getIngressStatus() {
      var that = this;

      var data = JSON.stringify({
        dataSource: this.cluster,
        database: "RAYO-IO",
        collection: "stock_ingress_status",
        filter: {},
        sort: {},
        limit: 1000,
      });

      var config = {
        method: "post",
        url: this.baseAPI + "/find",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      // console.log('BODY GET STATUSES', config.data)
      axios(config)
        .then(function (response) {
          console.log(response);
          that.ingressStatusList = response.data.documents;
          that.currentStatus = that.ingressStatusList.filter(
            (x) => x.status_code === 0
          )[0];
          that.snackText = "Recupero Status OK";
          that.snackMongo = true;
        })

        .catch(function (error) {
          console.log("ERROR", error);
          that.snackText = error;
          that.snackMongo = true;
        });
    },
    operacionRecupero() {
      this.status_code = 1;
      var that = this;

      var data = JSON.stringify({
        dataSource: this.cluster,
        database: "RAYO-IO",
        collection: "stock_ingress",
        filter: {
          usr: that.$store.state.user.data.email,
          status_code: that.status_code,
        },
        sort: {},
        limit: 1000,
      });

      var config = {
        method: "post",
        url: this.baseAPI + "/find",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      console.log("BODYREC", config.data);
      axios(config)
        .then(function (response) {
          console.log(response);
          that.currentStatus = that.ingressStatusList.filter(
            (x) => x.status_code === 0
          )[0];
          if (
            response.data.documents.length > 0 &&
            response.data.documents[0].status_code === 1
          ) {
            that.currentStatus = that.ingressStatusList.filter(
              (x) => x.status_code === 1
            )[0];
            that.inicioProceso = true;
            that.verPedidos = true;
            that.currentIngress = response.data.documents[0];
            that.botonFin = true;

            that.cantItems = response.data.documents[0].items.length;
          }
          that.snackText = "Recupero OK";
          that.snackMongo = true;
        })

        .catch(function (error) {
          console.log("ERROR", error);
          that.snackText = error;
          that.snackMongo = true;
        });
    },

    cerramos() {
      this.inicioProceso = false;
      this.backgroundColor = "background-color: #122337";
      this.arrayComunas = [];
      this.arrayScan = [];
      this.order_id = "";
      this.order_idScan = "";
      this.orderID = "";

      this.datosComunas = [];
      this.botonFin = false;
      this.cantItems = 0;
      this.recuperoData = [];
      this.verPedidos = false;
    },

    arrayZonas() {
      this.arrayComunas.push(this.datosComunas);
      console.log("ZONAS", this.arrayComunas);
    },

    // Seteo de order_id en onchange de text-field

    // setCode(v) {
    //   // this.order_id = v;
    //   this.order_idScan = this.order_id
    //   this.created()

    //   console.log('ORDER_ID', this.order_id)
    //   this.$refs.input2.focus()
    // }
  },

  mounted() {
    if (
      navigator.mediaDevices &&
      typeof navigator.mediaDevices.getUserMedia === "function"
    ) {
      console.log("Acceso correcto a mediaDevices");
    } else {
      alert(
        "NO se puede acceder a mediaDevices. Es posible que el scanner no funcione"
      );
    }
    // this.listarClientes()
    /// desarrollo
    // this.ff_order_id = '123'

    // this.dialogFFOrderId = false
    /// desarrollo

    // this.getItemFFOrderDetails()

    // this.getWarehouses()
    // this.getIngressStatus()
    // this.operacionRecupero()
  },
};
</script>

<style>
.text-white input {
  color: white !important;
}

.block {
  display: block;
  width: 100%;
  border: none;
  background-color: #04aa6d;
  color: white;
  padding: 14px 28px;
  font-size: 50px;
  cursor: pointer;
  text-align: center;
}

.block:hover {
  background-color: #ddd;
  color: black;
}

.stickyStyle {
  position: sticky;
  top: 48px;
  z-index: 9999;
}

.stickyStyleCamera {
  position: sticky;
  top: 118px;
  z-index: 9999;
}

.stickyStyleBarcode {
  position: sticky;
  top: 255px;
  z-index: 9999;
}
</style>
