<template>
  <div class="scanner-container">
    <div v-show="!isLoading">
     <!-- <div class="button-container">
        <button class="button" id="startButton" @click="onStart">Start</button>
        <button class="button" id="resetButton" @click="onReset">Reset</button>
      </div>-->
      
      <video class="videoClass" poster="data:image/gif, AAAA" id="video" ref="scanner" />
      <div class="overlay"></div>
      <div class="laser"></div>

      <div class="select" id="sourceSelectPanel" style="display: none">
        <!-- <label for="sourceSelect">Selecciona Cámara:</label> -->
     
       
        <v-select
        :items= "sourceSelect"
  
  
          
          style="max-width: 400px"
          v-model="selectedDeviceId"
        >
     
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import { BrowserMultiFormatReader, Exception } from '@zxing/library'

export default {
  name: 'stream-reader', 

  data () {
    return {
      
      isLoading: false,
      scanner: new BrowserMultiFormatReader(),
      isMediaStreamAPISupported:
        navigator &&
        navigator.mediaDevices &&
        'enumerateDevices' in navigator.mediaDevices,
      sourceSelect: [],
      selectedDeviceId: ''
    }
  },
  watch: {
    selectedDeviceId: 'onStart'
  },
  mounted () {
     console.log('a')
    navigator.permissions.query({name: 'camera'})
 .then((permissionObj) => {
  console.log(permissionObj.state);
 })
 .catch((error) => {
  console.log('Got error :', error);
 })
 
    if (!this.isMediaStreamAPISupported) {
    alert("Este dispositivo no permite el scaneo")
    }
    this.start()
    this.$refs.scanner.oncanplay = (event) => {
      this.isLoading = false
      this.$emit('loaded')
    }
  },
  beforeDestroy () {
    this.scanner.reset()
  },
  methods: {
    start () {
      this.scanner.listVideoInputDevices().then((videoInputDevices) => {
        this.selectedDeviceId = videoInputDevices[0].deviceId
        if (videoInputDevices.length >= 1) {
          videoInputDevices.forEach((element) => {
            const sourceOption = {}
            sourceOption.text = element.label
            sourceOption.value = element.deviceId
            this.sourceSelect.push(sourceOption)
          })

          const sourceSelectPanel =
            document.getElementById('sourceSelectPanel')
          sourceSelectPanel.style.display = 'block'
        }
      })
      this.onStart()
    },
    onStart () {

      this.scanner.decodeFromVideoDevice(
        this.selectedDeviceId,
        'video',
        (result, err) => {
          if (result) {
            // this.scannedCode = result.text
            this.$emit('decode', result.text)

          }
          if (err && !(err instanceof Exception)) {
            console.error(err)
          }
        }
      )
    },
    onReset () {
      this.scanner.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.videoClass {
  width: 100%;
      object-fit: cover;
    height: 125px;
}
/* 
video {
  max-width: 100%;
//  max-height: 100%;
} */
.button-container {
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
}
.scanner-container {
  position: relative;
}
// .overlay-element {
//   position: relative;
//   top: 0;
//   width: 100%;
//   height: 99%;
//   background: rgba(30, 30, 30, 0.5);
//   -webkit-clip-path: polygon(
//     0% 0%,
//     0% 100%,
//     20% 100%,
//     20% 20%,
//     80% 20%,
//     80% 80%,
//     20% 80%,
//     20% 100%,
//     100% 100%,
//     100% 0%
//   );
//   clip-path: polygon(
//     0% 0%,
//     0% 100%,
//     20% 100%,
//     20% 20%,
//     80% 20%,
//     80% 80%,
//     20% 80%,
//     20% 100%,
//     100% 100%,
//     100% 0%
//   );
// }
.laser {
  width: 100%;
  top:0px;
  margin-left: auto;
    margin-right: auto;
  background-color: rgb(29, 45, 184);
  height: 2px;
  position: absolute;
  // top: 40%;
  z-index: 2;
  box-shadow: 0 0 4px rgb(81, 0, 255);
  -webkit-animation: scanning 2s infinite;
  animation: scanning 2s infinite;
}
@-webkit-keyframes scanning {
  50% {
    -webkit-transform: translateY(75    px);
    transform: translateY(75px);
  }
}
@keyframes scanning {
  50% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }
}
</style>
